import portfolio from "../utils/portfolio";
import PortfolioRow from "../components/PortfolioRow";

export default function PortfolioSection(){
    return (
        <section id="portfolio">
            <div className="page__width my-[20%]">
                {
                    portfolio.map(project => {
                        return <PortfolioRow href={project.href} imageSrc={project.imageSrc} title={project.title} imageAlt={project.title} />
                    })
                }
            </div>
        </section>
    )
}
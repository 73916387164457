import mg12 from '../assets/img/portfolio-mg12.png'
import evo from '../assets/img/portfolio-evo.png'
import adnova from '../assets/img/portfolio-adnova.png'
import goodygo from '../assets/img/portfolio-goodygo.png'
import premiumservices from '../assets/img/portfolio-ps.jpg'

const portfolio = [
    {
        title: "Adnova",
        href: "https://www.adnova.agency",
        imageSrc: adnova
    },
    {
        title: "Premium Services",
        href: "https://premiumservices.bg/",
        imageSrc: premiumservices
    },
    {
        title: "GoodyGo",
        href: "https://goodygo.pl",
        imageSrc: goodygo
    },
    {
        title: "Mgnesium12",
        href: "https://mgnesium12.com",
        imageSrc: mg12
    },
    {
        title: "Ivaylo Ivanov Portfolio",
        href: "http://storyou.bg",
        imageSrc: evo
    }
]

export default portfolio;